import React, { Component, Fragment } from 'react'
import { translate } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { resolutions } from '../../utils/constants'
import { connect } from 'react-redux'
import Carousel from 'antd/lib/carousel'
import { mapDispatchToProps } from '../../helpers/redux.helper'
import ArrowSlider from './HomeNavigation/ArrowSlider'
import PointedSlider from './HomeNavigation/PointedSlider'
import About from '../Home/HomeAbout'
import VoiceRecording from '../Home/HomeVoiceRecording'
import Statistic from '../Home/HomeStatistic'
import Advantages from '../Home/HomeAdvantages'
import Service from '../Home/HomeService'
import Foreigns from '../Home/HomeForeigns'
import Reviews from '../Home/HomeReviews'
import Loader from '../Loader'
import './index.sass'
import '../Services/index.sass'
import ScrollUpButton from '../ScrollUpButton'

const paddingHeight = 14 * 2
const navigationHeight = 40

const ComponentWrapper = props => (
  <div style={{ height: `${props.height}px`, overflowY: 'auto' }}>
    <div className="content-wrap">
      <About {...props} />
      <Statistic activeComponent={props.activeComponent} {...props} />
    </div>
  </div>
)

const components = [
  <VoiceRecording key={1} />,
  <ComponentWrapper key={2} />,
  <Advantages key={3} />,
  <Service key={4} />,
  <Foreigns key={5} />,
  <Reviews key={6} />,
]

class Home extends Component {
  state = {
    activeComponent: 0,
    width: window.innerWidth,
    height: window.innerHeight,
  }

  updateDimensions = () => {
    const { activeComponent, width } = this.state
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
      activeComponent: width < resolutions.tablet ? 0 : activeComponent,
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  componentDidMount = () => {
    const {
      home,
      actions: { getHomeList },
    } = this.props
    window.addEventListener('resize', this.updateDimensions)
    if (Object.keys(home).length === 0) {
      getHomeList()
    }
  }

  handleChangeNextSlide = () => {
    this.slider.slick.slickNext()
  }

  handleChangePrevSlide = () => {
    this.slider.slick.slickPrev()
  }

  afterChange = index => {
    this.setState({ activeComponent: index })
  }

  chooseSelectedSlide = index => {
    this.slider.slick.slickGoTo(index)
  }

  render() {
    const { loading, home, t, lng } = this.props
    const { activeComponent, width, height } = this.state
    const extraHeight =
      width <= resolutions.small_desktop ? paddingHeight + navigationHeight : paddingHeight
    return (
      <Fragment>
        <Helmet>
          <html lang={lng} />
          <title>{t('meta.home.title')}</title>
          <link rel="alternate" hrefLang="en"
              href="https://unimedia.io" />
          <link rel="alternate" hrefLang="de"
              href="https://unimedia.io/de" />
          <link rel="alternate" hrefLang="ru"
              href="https://unimedia.io/ru" />
          <link rel="alternate" hrefLang="x-default"
              href="https://unimedia.io" />
          <meta name="description" content={t('meta.home.descr')} />
          {lng === 'en' && <meta name="keywords" content={t('meta.home.keywords')} />}
          {lng === 'en' ? <link rel="canonical" href={'https://unimedia.io'} /> : <link rel="canonical" href={`https://unimedia.io/${lng}`} />}
          <meta property="og:title" content={t('meta.home.title')} />
          <meta property="og:description" content={t('meta.home.descr')} />
          <meta property="og:image" content="https://unimedia.io/assets/logo.svg" />
          <meta property="og:url" content={lng === 'en' ? 'https://unimedia.io': `https://unimedia.io/${lng}`} />
          <script type="application/ld+json">
          {`[{
              "@context" : "https://schema.org/",
              "@type": "Product",
              "name": "${t('productDescription')}",
              "image": "https://unimedia.io/assets/logo.svg",
              "description": "${t('meta.home.descr')}",
              "brand": {
                "@type": "Brand",
                "name": "Universal Media"
              },
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "bestRating": "5",
                "reviewCount": "5"
              }
            },
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Universal Media",
                "alternateName": "Unimedia",
                "url": "https://unimedia.io",
                "logo": "https://unimedia.io/assets/logo.svg"
            }]`}
          </script>
        </Helmet>
        <ScrollUpButton id={'home'} />
        <div className="home-container" id={'home'} >
          {loading ? (
            <Loader />
          ) : (
            Object.keys(home).length && (
              <>
                {width < resolutions.tablet ? (
                  components.map((elem, ind) =>
                    React.cloneElement(elem, { ...this.props, key: ind })
                  )
                ) : (
                  <Carousel
                    dots={false}
                    infinite={true}
                    speed={700}
                    slidesToScroll={1}
                    slidesToShow={1}
                    arrows={false}
                    draggable={false}
                    ref={ref => (this.slider = ref)}
                    afterChange={this.afterChange}
                  >
                    
                    <VoiceRecording height={height - extraHeight} width={width} {...this.props} />
                   
                    <ComponentWrapper
                      activeComponent={activeComponent}
                      height={height - extraHeight}
                      width={width}
                      {...this.props}
                    />
                    <Advantages height={height - extraHeight} width={width} {...this.props} />
                    <Service height={height - extraHeight} width={width} {...this.props} />
                    <Foreigns height={height - extraHeight} width={width} {...this.props} />
                    <Reviews height={height - extraHeight} width={width} {...this.props} />
                  </Carousel>
                )}

                <ArrowSlider
                  totalComponents={components.length - 1}
                  activeComponent={activeComponent}
                  handleChangeNextSlide={this.handleChangeNextSlide}
                  handleChangePrevSlide={this.handleChangePrevSlide}
                />

                <PointedSlider
                  totalComponents={components.length - 1}
                  activeComponent={activeComponent}
                  handleChangeNextSlide={this.handleChangeNextSlide}
                  handleChangePrevSlide={this.handleChangePrevSlide}
                  chooseSelectedSlide={this.chooseSelectedSlide}
                />
              </>
            )
          )}
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  home: state.home,
  loading: state.app.loading,
})

export default translate('translations')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Home)
)
