import React from 'react'
import StatisticRate from '../../StatisticRate'
import './index.sass'

const Statistic = ({ home, activeComponent }) => {
  const homeStatistic = home.home.statistic
  const { isColoredBg } = home.home.settings
  return (
    <div className={isColoredBg ? 'rate rate-colored' : 'rate'}>
      <StatisticRate activeComponent={activeComponent} componentText={homeStatistic} />
    </div>
  )
}

export default Statistic
