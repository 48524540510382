import React from 'react'
import { translate } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Routes } from '../../../utils/constants'
import CountryIcon from '../../CountryIcon'
import './index.sass'

const Foreigns = ({ home, height, t, lng }) => {
  const homeForeigns = home.home.foreigns
  const { isColoredBg } = home.home.settings
  const path = lng === 'en' ? '' : `/${lng}`
  return (
    <div style={{ height: `${height}px`, overflowY: 'auto' }}>
      <div className={isColoredBg ? 'foreigns foreigns-colored' : 'foreigns'}>
        <div className="titles">
          <span className="titles_headline subtitle-2">{t('home.foreigns.subtitles')}</span>
          <h2 className="titles_main-title main-title">{t('home.foreigns.title')}</h2>
          <Link className="portfolio-link" to={`${path}${Routes.ALL_LANGUAGES}`}>
            <div className="portfolio-link-inner">
              <h5>{t('home.foreigns.allLanguages.subtitle')}</h5>
            </div>
          </Link>
          {lng !== 'de' &&
            <Link className="portfolio-link" to={`${path}${Routes.BLOG}`}>
              <div className="portfolio-link-inner">
                <h5>{t('home.foreigns.blog.title')}</h5>
              </div>
            </Link>
          }
        </div>
        <div className="body">
          <div className="body_grid-container">
            {homeForeigns.countries.map((name, index) => (
              <CountryIcon key={index} name={t(`home.foreigns.countries.${index}`)} path={name} index={index + 1} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default translate('translations')(Foreigns)
