import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import './index.sass'

class ButtonNavigator extends Component {
  constructor(props) {
    super(props)

    this.circle = React.createRef()
    this.button = React.createRef()
  }

  addCircleEffect = event => {
    const circleNode = this.circle.current
    const buttonNode = this.button.current
    const buttonElement = buttonNode.getBoundingClientRect()
    const x = event.clientX
    const y = event.clientY
    circleNode.classList.add('circle')
    circleNode.style.left = `${x - buttonElement.x}px`
    circleNode.style.top = `${y - buttonElement.y}px`
  }

  removeCicleEffect = () => {
    const circleNode = this.circle.current
    circleNode.classList.remove('circle')
  }

  render = () => {
    const { route, description, isArrowInside } = this.props
    return (
      <div className="btn-wrapper">
        <button
          ref={this.button}
          className="btn-navigator"
          onMouseDown={this.addCircleEffect}
          onMouseUp={this.removeCicleEffect}
          onMouseLeave={this.removeCicleEffect}
        >
          <NavLink className="btn-navigator_link" to={route}>
            {description}
            {isArrowInside ? <div className="arrow" /> : null}
            <div ref={this.circle} />
          </NavLink>
        </button>
      </div>
    )
  }
}

export default ButtonNavigator
