import React, { Component } from 'react'
import './index.sass'

class VideoPlayer extends Component {
  state = {
    isPlaying: false,
  }

  componentDidUpdate = prevProps => {
    if (prevProps.videoUrl !== this.props.videoUrl) {
      this.setState({
        isPlaying: false,
      })
    }
  }

  handlePlay = () => {
    this.video.play()
    this.setState({
      isPlaying: !this.video.paused,
    })
  }

  render = () => {
    const { videoUrl, thumbUrl } = this.props
    const { isPlaying } = this.state
    return (
      <div className="video">
        <video pleload="none" src={videoUrl} ref={ref => (this.video = ref)} controls={isPlaying} />
        {!isPlaying && (
          <>
            <button onClick={this.handlePlay} />
            {thumbUrl && <img className='video-thumb-main' alt='' src={thumbUrl} />}
          </>
        )}
      </div>
    )
  }
}

export default VideoPlayer
