import React from 'react'
import VideoSliderControls from '../../../VideoSliderControls'
import './index.sass'

const ArrowSlider = ({
  handleChangePrevSlide,
  handleChangeNextSlide,
  activeComponent,
  totalComponents,
}) => (
  <div className="home-navigation">
    <h3 className="home-navigation_slide-number" data-total={`/ 0${totalComponents + 1}`}>{`0${activeComponent + 1}`}</h3>
    <VideoSliderControls
      totalSlides={totalComponents}
      activeSlide={activeComponent}
      handleChangeNextSlide={handleChangeNextSlide}
      handleChangePrevSlide={handleChangePrevSlide}
    />
  </div>
)

export default ArrowSlider
