import React from 'react'
import './index.sass'

const VideoSliderControls = ({
  handleChangePrevSlide,
  handleChangeNextSlide,
  activeSlide,
  totalSlides,
  showDots,
  children,
}) => (
  <div className="video-slider-controls">
    <button onClick={handleChangePrevSlide} disabled={activeSlide === 0} className="prev" />
    {showDots && children}
    <button
      onClick={handleChangeNextSlide}
      disabled={activeSlide === totalSlides}
      className="next"
    />
  </div>
)

export default VideoSliderControls
