import React from 'react'
import { translate } from 'react-i18next'
import ServiceItem from '../../Services/Service'
import Wave from '../../Wave'
import { waveTypes } from '../../../utils/constants'
import { isSmallDisplayView } from '../../../helpers/device.helper'
import './index.sass'

const Services = ({ home, height, t }) => {
  const homeServices = home.home.services
  let waveType = waveTypes.SMOOTH_WAVE
  const { isColoredBg } = home.home.settings

  if (!isSmallDisplayView()) {
    waveType = waveTypes.SHARP_WAVE_HIGH_DEF
  }

  return (
    <div style={{ height: `${height}px`, overflowY: 'auto' }}>
      <div className={isColoredBg ? 'home-services home-services-colored' : 'home-services'}>
        <div className="titles">
          <span className="titles_headline subtitle-2">{t('home.services.subtitle')}</span>
          <h2 className="titles_main-title main-title">{t('home.services.title')}</h2>
        </div>
        <div className="services">
          {homeServices.services.map((services, ind) => {
            return (
              <ServiceItem key={ind} keyForTranslation={ind} title={t(`about.services.${ind}.title`)} servicesList={services.servicesList} />
            )
          })}
        </div>
        <div className="body_wave">
          <Wave type={waveType} />
        </div>
      </div>
    </div>
  )
}

export default translate('translations')(Services)
