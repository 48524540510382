import React, { Fragment, Component } from 'react'
import { translate } from 'react-i18next'
import CountUp from 'react-countup'
import './index.sass'

class StatisticRate extends Component {
  componentDidUpdate = () => {
    if(this.props.activeComponent === 1) {
			this.count0.restart()
			this.count1.restart()
			this.count2.restart()
    }
  }

  render = () => {
    const { componentText, t } = this.props
    return (
      <Fragment>
        <div className="statistic-info">
          {componentText.statistic.map((item, index) => {
						const name = `count${index}`
            return (
              <div key={index}>
                <span>
                  <CountUp
                    ref={ref => (this[[name]] = ref)}
                    redraw={true}
                    start={item.title - 5}
                    end={item.title}
                    duration={8}
                  />
                  +
                </span>
                <p className="subtitle-2">{t(`home.statistic.${index}.content`)}</p>
              </div>
            )
          })}
        </div>
      </Fragment>
    )
  }
}

export default translate('translations')(StatisticRate)
