import React from 'react'
import { translate } from 'react-i18next'
import SponsorIcon from '../../SponsorIcon'
import Review from '../../Review'
import { sponsors } from '../../../utils/constants'
import './index.sass'

const Reviews = ({ home, height, t }) => {
  const homeReview = home.home.mainReviews
	const { isColoredBg } = home.home.settings
  return (
    <div style={{ height: `${height}px`, overflowY: 'auto' }}>
      <div className={isColoredBg ? 'reviews reviews-colored' : 'reviews'}>
        <div className="titles">
          <span className="titles_headline subtitle-2">{t('home.reviews.subtitle')}</span>
          <h2 className="titles_main-title main-title">{t('home.reviews.title')}</h2>
        </div>
        <div className="body">
          <div className="body_reviews-container">
            {homeReview.mainReviews.map(({ reviewer, date }, index) => (
              <div key={index} className="body_review">
                <Review
                  reviewer={reviewer}
                  comment={t(`home.reviews.mainReviews.${index}.comment`)}
                  date={date}
                  country={t(`home.reviews.mainReviews.${index}.country`)}
                />
              </div>
            ))}
          </div>
          <div className="body_grid-sponsors">
            {sponsors.map((sponsor, index) => (
              <SponsorIcon key={index} name={sponsor} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default translate('translations')(Reviews)
