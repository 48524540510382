import React from 'react'
import { translate } from 'react-i18next'
import { Routes as AppRoutes } from '../../../utils/constants'
import ButtonNavigator from '../../ButtonNavigator'
import MainVideo from '../../MainVideo'
import './index.sass'

const VoiceRecording = ({ home, height, t, match, lng }) => {
  const { isColoredBg } = home.home.settings
  const mainText = t('home.homeVoices.title')
    .split('\n')
    .map((text, index) => <span key={index}>{text}</span>)
  const base = match.url.charAt(match.url.length - 1) === '/' ? match.url.slice(0, -1) : match.url
  return (
    <div className={`fix-${lng}`} style={{ height: `${height}px`, overflowY: 'auto' }}>
      <div className={isColoredBg ? 'voice-recording voice-recording-colored' : 'voice-recording'}>
        <div className="titles">
          {/* <span className="titles_headline subtitle-2">{t('home.homeVoices.subtitle')}</span> */}
          <h1 className="titles_main-title main-title">{mainText}</h1>
          <h2 className="titles-subtitle">{t('home.homeVoices.subtitle')}</h2>
        </div>
        {(lng === 'ru' || lng === 'en') && <MainVideo lng={lng} />}
        <div className="body">
          <p className="subtitle-1-light body_paragraph">{t('home.homeVoices.homeVoices.0')}</p>
          <div className="body_navigation-btn">
            <ButtonNavigator
              route={`${base}${AppRoutes.VOICES}`}
              description={t('home.homeVoices.button')}
              isArrowInside={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default translate('translations')(VoiceRecording)
