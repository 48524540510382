import React, { Fragment } from 'react'
import { translate } from 'react-i18next'
import { Routes as AppRoutes } from '../../../utils/constants'
import ButtonNavigator from '../../ButtonNavigator'
import './index.sass'

const About = ({ home, t, match }) => {
	const homeAbout = home.home.homeAbout
	const base = match.url.charAt(match.url.length - 1) === '/' ? match.url.slice(0, -1) : match.url
  return (
    <div className="about">
      <div className="titles">
        <span className="titles_headline subtitle-2">{t('home.homeAbout.subtitle')}</span>
        <h2 className="titles_main-title main-title">{t('home.homeAbout.title')}</h2>
      </div>
      <div className="body">
        {homeAbout.homeAbout.map((_, index) =>
          index + 1 !== homeAbout.homeAbout.length ? (
            <Fragment key={index}>
              <p className="body-light">{t(`home.homeAbout.homeAbout.${index}`)}</p>
              <br />
            </Fragment>
          ) : (
            <p key={index} className="body-light" dangerouslySetInnerHTML={{ __html: t(`home.homeAbout.homeAbout.${index}`) }} />
          )
        )}
        <div className="body_navigation-btn">
          <ButtonNavigator
            route={`${base}${AppRoutes.ABOUT}`}
            description={t('home.homeAbout.button')}
            isArrowInside={true}
          />
        </div>
      </div>
    </div>
  )
}

export default translate('translations')(About)
