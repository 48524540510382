import React from 'react'
import './index.sass'

const SponsorIcon = ({ name, width, height }) => {
  const waveWidth = width || '100%'
  const waveHeight = height || 'auto'

  return (
    <div className="icon-wrapper sponsor">
      <img
        className="icon-wrapper_img"
        src={`/assets/sponsors/${name}.svg`}
        alt={name}
        width={waveWidth}
        height={waveHeight}
      />
    </div>
  )
}

export default SponsorIcon
