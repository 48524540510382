import React from 'react'
import './index.sass'

const Review = ({ reviewer, comment, date, country }) => (
  <div className="review-wrapper">
    <h3 className="review-wrapper_reviewer subtitle-1">{reviewer}</h3>
		<p className='body-light review-wrapper_country'>{country}</p>
    <p className="body-light review-wrapper_comment">{comment}</p>
    <span className="review-wrapper_date date">{date}</span>
  </div>
)

export default Review
