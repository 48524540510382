import React from 'react'
import VideoPlayer from '../VideoPlayer'

const MainVideo = ({ lng }) => {
  const path =
    lng === 'ru' ? '/assets/video/ru/but_all_final_2.mp4' : '/assets/video/en/but_eng.mp4'
  return (
    <div className="video-hp">
      <VideoPlayer videoUrl={path} />
    </div>
  )
}

export default MainVideo
