import React from 'react'
import { translate } from 'react-i18next'
import './index.sass'

const Advantages = ({ home, height, t }) => {
  const homeAdvantages = home.home.chooseUs
  const { isColoredBg } = home.home.settings
  return (
    <div style={{ height: `${height}px`, overflowY: 'auto' }}>
      <div className={isColoredBg ? 'advantages advantages-colored' : 'advantages'}>
        <div className="titles">
          <span className="titles_headline subtitle-1">{t('home.chooseUs.subtitles')}</span>
          <h2 className="titles_main-title main-title">{t('home.chooseUs.title')}</h2>
        </div>
        <div className="body">
          {homeAdvantages.chooseUs.map((_, index) => (
            <div className="advantage" key={index}>
              <h4 className="body_title subtitle-1">{t(`home.chooseUs.${index}.title`)}</h4>
              <p
                className="body-light body_paragraph"
                dangerouslySetInnerHTML={{ __html: t(`home.chooseUs.${index}.text`) }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default translate('translations')(Advantages)
