import React, { useEffect, useState } from 'react'
import './index.sass'


const ScrollUpButton = ({id}) => {
    const [scrollValue, setScrollValue] = useState(0)
    const root = document.getElementById(id)
    const scrollUp=()=>{
        root.scrollTo({top:0,left:0, behavior: 'smooth'})
    }

    useEffect(()=>{
        window.addEventListener('scroll', ()=>{ if(root) setScrollValue(root.scrollTop)}, true)
        return  window.removeEventListener('scroll', ()=>setScrollValue(0))
    })
    return (
        <div className={scrollValue>400 ? 'button' : 'invisible'} onClick={scrollUp}>
            <img src='/assets/arrow-up.svg' className="arrow" />
        </div>
    )
}
  


export default ScrollUpButton