import React from 'react'
import { translate } from 'react-i18next'
import './index.sass'

const ServiceItem = ({ title, servicesList, t, keyForTranslation }) => (
  <div className="service-item">
    <p className="subtitle-1">{title}</p>
    <ul>
      {servicesList.map((_, index) => (
        <li key={index} className="body-light">
          {t(`about.services.${keyForTranslation}.${index}`)}
        </li>
      ))}
    </ul>
  </div>
)

export default translate('translations')(ServiceItem)
