import React from 'react'
import { translate } from 'react-i18next'
import './index.sass'

const PointedSlider = ({
  handleChangePrevSlide,
  handleChangeNextSlide,
  activeComponent,
  totalComponents,
	chooseSelectedSlide,
	t,
}) => {
  const interceptionWidth = 60
  const dotWidth = 8
  const svgLineWidth = totalComponents * interceptionWidth + totalComponents * dotWidth
  const svgActiveLineWidth = activeComponent * interceptionWidth + activeComponent * dotWidth
  const getSelectedClassName = index => {
    if (activeComponent === index) {
      return 'selected'
    }
  }

  const getVisitedClassName = index => {
    if (index <= activeComponent) {
      return 'visited'
    }
  }
  return (
    <div className="pointed-slider">
      <button
        className="pointed-slider_prev"
        onClick={handleChangePrevSlide}
        disabled={activeComponent === 0}
      >
        {t('general.prev')}
      </button>
      {Array(totalComponents + 1)
        .fill(1)
        .map((item, index) => (
          <div key={index} className={'dot ' + getVisitedClassName(index)}>
            <div
              className={'dot-wrapper ' + getSelectedClassName(index)}
              onClick={() => chooseSelectedSlide(index)}
            />
          </div>
        ))}
      <svg className="background-line" height="50" width={svgLineWidth}>
        <line x1="0" y1="25" x2={svgLineWidth} y2="25" stroke="#999999" />
        <line x1="0" y1="25" x2={svgActiveLineWidth} y2="25" stroke="#eeeeee" strokeWidth="2" />
      </svg>
      <button
        className="pointed-slider_next"
        onClick={handleChangeNextSlide}
        disabled={activeComponent === totalComponents}
      >
        {t('general.next')}
      </button>
    </div>
  )
}

export default translate('translations')(PointedSlider)
