import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Routes as AppRoutes } from '../../utils/constants'
import { Link } from 'react-router-dom'
import { resolutions } from '../../utils/constants'
import './index.sass'

class CountryIcon extends Component {
  state = {
    displayWidth: window.innerWidth,
  }

  updateDimensions = () => {
    this.setState({ displayWidth: window.innerWidth })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.updateDimensions)
  }

  render = () => {
    const { name, index, width, height, path, match } = this.props
    const { displayWidth } = this.state
    const waveWidth = width || '100%'
    const waveHeight = height || 'auto'
    let area
    let evenness

    if (displayWidth < resolutions.small_desktop) {
      index & 1
        ? (area = `${Math.round(index / 2)}/1/${Math.round(index / 2)}/3`)
        : (area = `${Math.round(index / 2)}/3/${Math.round(index / 2)}/5`)
      Math.round(index / 2) & 1 ? (evenness = 'odd') : (evenness = 'even')
    } else {
      index & 1 ? (area = `${index}/1/${index}/2`) : (area = `${index}/2/${index}/3`)
      index & 1 ? (evenness = 'odd') : (evenness = 'even')
    }

    const gridStyle = {
      gridArea: `${area}`,
    }
    const base = match.url.charAt(match.url.length - 1) === '/' ? match.url.slice(0, -1) : match.url
    return (
      <Link
        className={`icon-wrapper country country-${evenness}`}
        style={gridStyle}
        key={index}
        to={`${base}${AppRoutes.VOICES}/${path.toLowerCase().split(' ').join('-')}`}
      >
        <img
          className="icon-wrapper_img"
          src={`/assets/countries/${path}.svg`}
          alt={path}
          width={waveWidth}
          height={waveHeight}
        />
        <h4 className="icon-wrapper_title subtitle-2">{name}</h4>
      </Link>
    )
  }
}

export default withRouter(CountryIcon)
