import React from 'react'
import './index.sass'

const Wave = ({ type, width }) => {
  const waveWidth = width || '100%'

  return (
    <img className="img" src={`/assets/${type}.webp`} alt={type} width={waveWidth} />
  )
}

export default Wave
